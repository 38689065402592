<template>
    <div class="vanilla-item" :class="{ 'no-badge': !badgeText }" @click="onClickItem">
        <div v-if="badgeText" class="badge flex-wrap" v-html="badgeText"></div>
        <div class="name f-medium">{{ item.name }}</div>
        <div class="price c-primary">
            <span class="f-bold">{{ item.price.toLocaleString() }}</span
            >원
        </div>
    </div>
</template>
<script>
export default {
    name: 'VanillaItem',
    props: ['item'],
    computed: {
        badgeText() {
            if (!this.item) return ''

            if (this.item.discount > 40) {
                return `<span>실속!</span><span class="f-bold">${this.item.discount}%</span><span>할인</span>`
            }

            if (this.item.discount > 15) {
                return `<span>인기!</span><span class="f-bold">${this.item.discount}%</span><span>할인</span>`
            }

            if (this.item.discount > 0) {
                return `<span class="f-bold">${this.item.discount}%</span> 할인`
            }

            return ''
        },
    },
    methods: {
        onClickItem() {
            this.$emit('click', this.item)
        },
    },
}
</script>
