<template>
    <div class="in-app-store">
        <StackRouterHeaderBar :title="$translate('IN_APP_STORE')" :showTitle="true" />
        <RefundInfoBanner :type="'inApp'" />
        <div class="contents">
            <div class="section vanilla-purchase">
                <div class="head flex-row items-center flex-between">
                    <div class="title f-medium" v-html="'바닐라 구매'" />
                    <div class="vanilla">
                        <span class="text">보유 바닐라</span>
                        <span class="count c-primary">
                            <span class="f-bold">{{ myVanilla }}</span
                            >개
                        </span>
                    </div>
                </div>
                <div class="vanilla-items" :class="gender === 0 ? 'male' : 'female'">
                    <VanillaItem v-for="item in vanillaItems" :key="item.id" :item="item" @click="onClickItem" />
                </div>
                <p class="vat">* 모든 상품은 VAT 별도입니다.</p>
            </div>
            <div v-if="isFemale" class="section super-privacy-purchase">
                <div class="head flex-row items-center flex-between">
                    <div class="title f-medium" v-html="'슈퍼 프라이버시'" />
                    <!-- <div class="super-privacy">
                        <span class="text">보유 바닐라</span>
                        <span class="count c-primary">
                            <span class="f-bold">{{ myVanilla }}</span
                            >개
                        </span>
                    </div> -->
                </div>
                <div class="super-privacy-banner" @click="onClickSuperPrivacy">
                    <div class="super-privacy-comment" v-html="'내 프로필이 여기저기 보이는게 싫다면?'" />
                    <div class="super-privacy-mode" v-html="'슈퍼 프라이버시 모드'" />
                    <div class="super-privacy-more flex-row">
                        <div class="text" v-html="'더 알아보기'" />
                        <i class="material-icons i-right">chevron_right</i>
                    </div>
                    <!-- <VanillaItem v-for="item in vanillaItems" :key="item.id" :item="item" @click="onClickItem" /> -->
                </div>
                <!-- <p class="vat">* 모든 상품은 VAT 별도입니다.</p> -->
            </div>
        </div>
        <div class="footer">
            <div v-if="!$isTester()" class="m-b-28">
                <div class="title">소개팅권 구매 안내</div>
                <div class="content">소개 상대와 연락처 교환 시, 채팅방에서 구매 가능합니다.</div>
            </div>
            <div>
                <div class="title">결제 오류 문의</div>
                <div class="content">
                    <ul>
                        <li>카카오톡 플러스친구 <span>바닐라브릿지</span>로 문의 부탁드립니다<br /></li>
                        <li>불편을 드려 죄송합니다.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RefundInfoBanner from './components/RefundInfoBanner'
import VanillaItem from './components/VanillaItem'
import productService from '@/services/product'

export default {
    name: 'InAppStorePage',
    components: {
        RefundInfoBanner,
        VanillaItem,
    },
    data: () => ({
        vanillaItems: null,
        selectedProduct: null,
    }),
    computed: {
        myVanilla() {
            return (this.$store.getters.badges || {}).vanilla
        },
        gender() {
            return (this.$store.getters.me || {}).gender
        },
        isFemale() {
            return this.gender === 1
        },
    },
    created() {
        this.init()
    },
    methods: {
        async init() {
            try {
                const { vanilla } = await productService.all()
                this.vanillaItems = vanilla
            } catch (e) {}
        },
        onClickItem(item) {
            const appVersion = this.$store.getters.device.app_version
            if (appVersion < '1.2.0') {
                this.$toast.error('앱 업데이트 후 진행이 가능합니다. 앱을 업데이트 해주세요!')
                return
            }

            this.selectedProduct = item
            this.$store.dispatch('requestInAppPurchase', item)
        },
        onClickSuperPrivacy() {
            this.$stackRouter.push({ name: 'SuperPrivacyIntroductionPage' })
        },
    },
}
</script>

<style lang="scss" scoped>
.super-privacy-purchase {
    //   margin-bottom: 48px;

    .super-privacy-banner {
        width: 100%;
        height: 154px;
        border-radius: 12px;
        padding-top: 25px;
        padding-bottom: 18px;
        background-image: linear-gradient(to bottom, $blue-facebook, #4f2eff 130%);

        .super-privacy-comment {
            // margin-top: 20px;
            @include f-regular;
            font-size: 14px;
            color: white;
            text-align: center;
            opacity: 0.8;
        }

        .super-privacy-mode {
            @include f-bold;
            font-size: 24px;
            height: 34px;
            color: white;
            text-align: center;
            margin-top: 1px;
            margin-bottom: 20px;
            padding: 5px 0;
        }

        .super-privacy-more {
            width: 124px;
            height: 36px;
            background-color: white;
            border-radius: 8px;
            margin-top: 18px;
            margin-bottom: 18px;
            text-align: center;
            margin: 0 30vw;
            padding: 0 20px;

            .text {
                @include f-medium;
                font-size: 13px;
                text-align: center;
                color: black;
                align-self: center;
            }
            .i-right {
                color: black;
                font-size: 13px;
                align-self: center;
                // background-color: black;
            }
        }
    }

    .vat {
        font-size: 11px;
        color: $grey-05;
    }
}
</style>
